<!--
 * @Description: 项目中心 > 项目日常管理 > 鹰眼组配置 > 鹰眼组管理 > 配置物管鹰眼
 * @Author: lulu
 * @Date: 2019-08-13 13:32:57
 * @LastEditors: 小广
 * @LastEditTime: 2019-08-20 15:09:21
 -->
<template>
  <div class="propertyMgEagleeyeForm-container">
    <form-panel ref="formPanel" :form="form"  v-bind="submitConfig" :submitUrl="submitUrl" submitText="保存" :submitBefore="submitBefore">
      <col2-block title="基础信息">
        <col2-item>
          <el-form-item label="归属项目" :rules="[{ required: true, message: '请选择归属项目', trigger: 'change' }]">
            <v-select2  placeholder="查询项目" v-model="communityId" :disabled="disabledCommunity"  v-bind="communityParams"  @onChange="getCommunityId"></v-select2>
          </el-form-item>
          <el-form-item label="鹰眼组名称" :rules="[{ required: true, message: '请选择鹰眼'}]" prop="groupId">
            <v-select v-model="form.groupId" :options="groupIdOps"></v-select>
          </el-form-item>
          <el-form-item label="目标对象" prop="userId" :rules="[{required: true, message: '请选择目标对象'}]">
            <chosenListPanel :list.sync="form.userId" @select="choseRelationInfo"></chosenListPanel>
          </el-form-item>
        </col2-item>
      </col2-block>
    </form-panel>
    <multiSelect
      :isShow.sync="isShowMultiSelect"
      :searchUrl="multiSelectUrl"
      :headers="multiSelectHeader"
      :backFill.sync="form.userId"
      :searchParams="searchelseParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      title="物管列表"
      :extraParams="extraParams"
      @callback="multiSelectcallback">
        <template #searchSlot>
          <v-input v-model="searchelseParams.userName" label="姓名"></v-input>
          <v-input v-model="searchelseParams.phoneNum" label="手机号"></v-input>
        </template>
    </multiSelect>
  </div>
</template>
<script>
import * as allEagleeyeUrl from './api'
import { ChosenListPanel, MultiSelect, Col2Block, Col2Item } from 'components/bussiness'
import { normalCommunityParams } from 'common/select2Params'

export default {
  components: {
    MultiSelect,
    ChosenListPanel,
    Col2Block,
    Col2Item
  },
  data () {
    return {
      form: {
        groupId: undefined,
        userId: []
      },
      disabledCommunity: false,
      isShowMultiSelect: false,
      eagleeye: [],
      submitConfig: {
        submitContentType: 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      communityId: undefined,
      extraParams: {
        communityId: 'undefined'
      },
      groupIdOps: [],
      communityParams: normalCommunityParams,
      multiSelectUrl: allEagleeyeUrl.getWuGuanListUrl,
      responseKey: {
        id: 'id',
        name: 'text'
      },
      submitUrl: allEagleeyeUrl.saveUsageUrl,
      multiSelectHeader: [{
        prop: 'userName',
        label: '姓名'
      }, {
        prop: 'loginName',
        label: '登录名'
      }, {
        prop: 'phoneNum',
        label: '手机号'
      }, {
        prop: 'communityName',
        label: '所属项目'
      }, {
        prop: 'intime',
        label: '录入时间'
      }, {
        prop: 'roleName',
        label: '角色'
      }],
      searchelseParams: {
        userName: '',
        phoneNum: ''
      },
      responseParams: {
        id: 'id',
        name: 'userName'
      }
    }
  },
  created () {
    this.$setBreadcrumb('配置物管鹰眼')
    const userType = String(this.$store.state.userInfo.userType)
    if (userType === '101') {
      this.disabledCommunity = true
      const communityId = this.$store.state.userInfo.communityId
      this.communityId = communityId
      this.getGroupInfo(communityId)
    }
  },
  methods: {
    multiSelectcallback (items) {
      this.form.userId = items
    },
    getCommunityId (data, echo) {
      if (!echo) {
        this.groupIdOps = []
        this.form.groupId = undefined
        if (this.form.userId && this.form.userId.length) {
          this.form.userId = []
        }
        if (data) {
          this.extraParams.communityId = data.id
          this.getGroupInfo(data.id)
        } else {
          this.extraParams.communityId = 'undefined'
        }
      }
    },
    getGroupInfo (id) {
      const _this = this
      _this.$axios.post(allEagleeyeUrl.getEagleeyeByCommunityUrl, { communityId: id })
        .then(res => {
          let returnStatus = String(res.status)
          if (returnStatus === '100') {
            res.data.forEach(function (item) {
              _this.groupIdOps.push({
                text: item.name,
                value: item.id
              })
            })
          }
        })
    },
    choseRelationInfo () {
      this.isShowMultiSelect = true
    },
    submitBefore (data) {
      data.userId = JSON.stringify(this.form.userId.map(item => item.id))
      return true
    }
  }
}
</script>
