var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "propertyMgEagleeyeForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
              footerShow: _vm.footerShow,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-block",
            { attrs: { title: "基础信息" } },
            [
              _c(
                "col2-item",
                [
                  _c("el-form-item", { attrs: { label: "鹰眼组名称" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.form.groupName))]),
                  ]),
                  _c("el-form-item", { attrs: { label: "归属项目" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.communityName))]),
                  ]),
                  _c("el-form-item", { attrs: { label: "使用者" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.userInfo))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isShowOps
            ? _c(
                "col2-block",
                { attrs: { title: "操作信息" } },
                [
                  _c(
                    "col2-item",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "状态",
                            prop: "status",
                            rules: [
                              {
                                required: true,
                                message: "请选择状态",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.statusOps },
                            model: {
                              value: _vm.form.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "status", $$v)
                              },
                              expression: "form.status",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.form.status === 3 || _vm.form.status === 4
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "描述",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入描述",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-textarea", {
                                attrs: { type: "textarea", maxlength: 200 },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark",
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "el-form-item",
                            { attrs: { label: "描述" } },
                            [
                              _c("v-textarea", {
                                attrs: { type: "textarea", maxlength: 200 },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark",
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }