// 获取列表
const getListURL = `${API_CONFIG.baseURL}serverEagleeyeAction!list.action`
// 导出列表
const exportListURL = `${API_CONFIG.baseURL}serverEagleeyeAction!exportToExcel.action`
// 批量操作
const batchURL = `${API_CONFIG.baseURL}smUserAccountAction!batchUpdateRechargeCardStatus.action`
// 鹰眼摄像列表
const multiSelectUrl = `${API_CONFIG.baseURL}serverEagleeyeAction!getCameraList.action`
// 鹰眼组
const createEagleeyeUrl = `${API_CONFIG.baseURL}serverEagleeyeAction!save.action`
// 目标对象
const getWuGuanListUrl = `${API_CONFIG.baseURL}serverOwnerAction!getWuGuanList.action`
// 鹰眼组名称
const getEagleeyeByCommunityUrl = `${API_CONFIG.baseURL}serverEagleeyeAction!queryEagleeyeByCommunity.action`
// 物管鹰眼
const saveUsageUrl = `${API_CONFIG.baseURL}serverEagleeyeAction!saveUsage.action`
// 鹰眼组详情
const getEagleeyeDetailUrl = `${API_CONFIG.baseURL}serverEagleeyeAction!queryOne.action`
// 鹰眼组编辑
const updateEagleeyeUrl = `${API_CONFIG.baseURL}serverEagleeyeAction!update.action`

export {
  getListURL,
  exportListURL,
  batchURL,
  multiSelectUrl,
  createEagleeyeUrl,
  getWuGuanListUrl,
  getEagleeyeByCommunityUrl,
  saveUsageUrl,
  getEagleeyeDetailUrl,
  updateEagleeyeUrl
}
